import ima1 from './main.jpg';
import './App.css';
import { Center, MantineProvider, Stack, Image, Button, Group, Text} from '@mantine/core';
import { IconBrandInstagram, IconBrandWhatsapp, IconClock,IconMapPin, IconPhoneIncoming } from '@tabler/icons-react';

function App() {
  return <MantineProvider theme={{fontFamily: 'Open Sans' }} withGlobalStyles withNormalizeCSS>
    <Center p={15}>
      <Stack spacing="xs">
        <div style={{ width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
          <Image
            radius="lg"
            fit="contain"
            src={ima1}
          />
        </div>
        <Group >
          <IconClock />
          <Text fw={700}>Orari di apertura:</Text>
        </Group>
        <Group ml={40} position="apart">
          <Text size="sm">Martedì</Text>
          <Text size="sm">10:00 - 18:00</Text>
        </Group>
        <Group ml={40} mt={0} position="apart">
          <Text size="sm">Mercoledì</Text>
          <Text size="sm">10:00 - 18:00</Text>
        </Group>
        <Group ml={40} mt={0} position="apart">
          <Text size="sm">Giovedì</Text>
          <Text size="sm">09:00 - 19:30</Text>
        </Group>
        <Group ml={40} mt={0} position="apart">
          <Text size="sm">Venerdì</Text>
          <Text size="sm">09:00 - 19:30</Text>
        </Group>
        <Group ml={40} mt={0} position="apart">
          <Text size="sm">Sabato</Text>
          <Text size="sm">09:00 - 19:30</Text>
        </Group>
        <div style={{ width: 400 }}>
          <Text size="md">Riceviamo solo su appuntamento, prenota ora attraverso i nostri social:</Text>
          </div>
          <Button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/message/7ZIMT5KFOXENO1"
            leftIcon={<IconBrandWhatsapp />}
            radius="lg"
          >
            Whatsapp
          </Button>

          <Button
            component="a"
            radius="lg"
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/criseldiamante?igshid=YmMyMTA2M2Y="
            leftIcon={<IconBrandInstagram />}
          >
            Instagram
          </Button>
          <Text size="md">Oppure chiamando:</Text>
          <Button
            component="a"
            radius="lg"
            target="_blank"
            rel="noopener noreferrer"
            href="tel:+393920511173"
            leftIcon={<IconPhoneIncoming />}
          >
            +39 392 051 1173
          </Button>
        <Group >
          <IconMapPin />
          <Text fw={700}>Dove siamo:</Text>
        </Group>
        <iframe title="Dove siamo" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.635622973616!2d9.206302816022621!3d45.47714357910122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6c0f7e0f37b%3A0x894205926cf7a59d!2sCorso%20Buenos%20Aires%2C%2018%2C%2020129%20Milano%20MI!5e0!3m2!1sit!2sit!4v1676495690096!5m2!1sit!2sit"
          width="400"
          height="200" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          {/* <Group >
          <IconMapPin />
          <Text fw={700}>Alcuni dei nostri lavori:</Text>
        </Group>
        <div style={{ width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
          <Carousel slideSize="70%" slideGap="md" withIndicators>
            <Carousel.Slide><Image
              radius="lg"
              fit="contain"
              src={ima2}
            /></Carousel.Slide>
            <Carousel.Slide><Image
              radius="lg"
              fit="contain"
              src={ima3}
            /></Carousel.Slide>
            <Carousel.Slide><Image
              radius="lg"
              fit="contain"
              src={ima4}
            /></Carousel.Slide>
          </Carousel>
        </div> */}
      </Stack>
    </Center>
  </MantineProvider>
}

export default App;
